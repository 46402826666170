<template>
  <div class="login-container">
    <div class="login">
      <div class="c-333333 rem44">短信验证码登录</div>
      <div class="c-8E929B rem30 mt10">请验证您的手机号</div>
      <div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="formData.mobile"
            class="username"
            :left-icon="passwordPng"
            placeholder="请输入注册手机号码"
            type="digit"
            maxlength="11"
          />
          <van-field
            v-model="formData.code"
            :left-icon="codePng"
            type="digit"
            placeholder="请输入短信验证码"
            class="mt20"
            @blur="codeInput"
            maxlength="6"
          >
            <template #extra>
              <span class="rem28 c-4D7BFE" @click="getCode" v-if="countDown == 0">{{
                codetext
              }}</span>
              <span class="rem28 c-4D7BFE" v-else>{{ countDown }}s</span>
            </template>
          </van-field>
          <div class="submitBtn">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              :disabled="!formData.mobile || !formData.code"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
      <div class="flex align-center mt40">
        <van-checkbox v-model="agreement" checked-color="#4D7BFE"></van-checkbox>
        <!-- <img
          :src="notChoosePng"
          alt=""
          @click="agreeFn(true)"
          v-if="!agreement"
          class="mr8 img14"
        />
        <img :src="choosedPng" alt="" @click="agreeFn(false)" v-else class="mr8 img14" /> -->
        <span class="readed rem26"
          >阅读并同意<span
            class="c-4D7BFE"
            @click="seeAgree(item)"
            v-for="item in agreeArr"
            :key="item.dictValue"
            >《{{ item.dictCode }}》</span
          ></span
        >
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Form, Field, Button, Radio, RadioGroup, Checkbox, Toast } from 'vant'
import { getSendCodeApi } from '@/apis/credit'
import { getRegAgreementNumApi } from '@/apis/userCenter'
import { Encrypt } from '@/utils/crypto'

Vue.use(Form).use(Field).use(Button).use(Radio).use(RadioGroup).use(Checkbox)

export default {
  data() {
    return {
      formData: {
        code: '',
        mobile: '',
      },
      passwordPng: require('@/assets/images/login/password.png'),
      codePng: require('@/assets/images/login/code.png'),
      notChoosePng: require('@/assets/images/index/notChoose.png'),
      choosedPng: require('@/assets/images/index/choosed.png'),
      agreement: false,
      codetext: '获取验证码',
      countDown: 0,
      status: 'ready',
      agreeArr: [],
    }
  },
  created() {
    this.getAgree()
    if (location.href.indexOf('productType') != -1) {
      this.productCode = this.getURLParam('productType')
    } else {
      this.productCode = 'cbsj_cgd'
    }
    if (location.href.indexOf('appId') != -1) {
      this.$store.commit('SET_APPID', this.getURLParam('appId'))
    }
  },
  computed: {},
  methods: {
    getURLParam(name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)', 'ig').exec(location.search) || [
            ,
            '',
          ])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    onSubmit() {
      const loadingToast = Toast.loading({
        message: '正在登录...',
        forbidClick: true,
        duration: 0,
      })
      if (!this.$validate.isPhone(this.formData.mobile)) {
        this.$common.toast('请输入正确的手机号')
        return
      }
      if (!this.agreement) {
        this.$common.toast('请阅读并同意平台服务协议')
        return
      }

      let { subChannel } = this.$store.state.channel || {}
      subChannel = subChannel ? subChannel : ''
      const query = { ...this.formData, subChannel, appId: this.$store.state.common.appId }
      this.$store
        .dispatch('Login', query)
        .then(() => {
          this.codetext = '重新获取'
          this.status = 'ready'
          this.countDown = 0
          clearInterval(this.timer)
          this.$router.push({ path: '/cgd' })
          loadingToast.clear()
        })
        .catch(() => {
          loadingToast.clear()
        })
    },
    getAgree() {
      getRegAgreementNumApi().then((res) => {
        if (res.code == 200) {
          this.agreeArr = res.data
        }
      })
    },
    // 阅读协议
    seeAgree(item) {
      this.$router.push({
        path: '/view-contract',
        query: {
          file: JSON.stringify([item]),
          type: 'login',
        },
      })
    },
    codeInput() {
      if (this.formData.code.length === 6) {
        this.countDown = 0
        this.codetext = '重新获取'
        clearInterval(this.timer)
      }
    },

    // 获取验证码
    getCode() {
      if (!this.$validate.isPhone(this.formData.mobile)) {
        this.$common.toast('请输入正确的手机号')
        return
      }
      if (this.status == 'ready') {
        this.codetext = '获取中'
        this.status = 'padding'
        this.$store.dispatch('LogOut').then(() => {
          getSendCodeApi({ mobile: this.formData.mobile, appId: this.$store.state.common.appId })
            .then((res) => {
              if (res.code === 200) {
                if (!this.timer) {
                  this.countDown = 60
                  this.timer = setInterval(() => {
                    if (this.countDown > 0 && this.countDown <= 60) {
                      this.countDown--
                      if (this.countDown === 0) this.codetext = '重新获取'
                      this.status = 'ready'
                    } else {
                      clearInterval(this.timer)
                      this.timer = null
                      this.status = 'ready'
                    }
                  }, 1000)
                }
              } else {
                this.status = 'ready'
              }
            })
            .catch((_) => {
              this.codetext = '重新获取'
              this.status = 'ready'
            })
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.login-container {
  height: 100vh;
  background-color: #f5f6fa;
  padding: 0 0.52rem 0 0.48rem;
  .login {
    padding-top: 1.52rem;
    .username {
      margin-top: 0.68rem;
    }
  }
}
/deep/ .van-field__value {
  display: inherit;
}
/deep/ .van-field__value:before {
  content: '|';
  display: inline-block;
  height: 0.28rem;
  width: 1px;
  color: #4d7bfe;
  margin-right: 0.24rem;
}
/deep/ .van-field__left-icon {
  margin-right: 0.14rem;
}
.van-cell {
  box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.12rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
}
.readed {
  color: #b0b0b0;
  margin-left: 0.1rem;
}
.submitBtn {
  margin-top: 1.44rem;
}
.van-button--normal {
  font-size: 0.34rem;
}
/deep/ .van-field__left-icon .van-icon {
  font-size: 0.48rem;
}
</style>
